<template>
    <div class="savoir-faire">
        <span>Notre savoir faire</span>
        <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde beatae itaque earum enim provident. Deleniti dolore iusto, maiores blanditiis, placeat consequuntur voluptatibus accusantium ipsa assumenda, pariatur aliquam possimus quam minima.
        </p>
        <div>
            <i class="fa-solid fa-plus"></i>
        </div>
    </div>
</template>
<script setup>

</script>
<style scoped>
.savoir-faire {
    background-image: url("/images/notre-savoir-faire/polissage-ldisas.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 332px;
    color: #fff;
    font-family: Barlow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10rem 0;
}

.savoir-faire span {
    font-size: 50px;
    text-transform: uppercase;
}

.savoir-faire p {
    font-size: 20px;
    text-align: center;
    width: 40%;
}

.savoir-faire i {
    font-size: 20px;
}
</style>