<template>
    <div class="containerSlide">
        <div class="slideCarousel">
            <span class="title">Nos actualités</span>
        </div>
        <div class="listActualites">
            <div class="divActualite">
                <div class="imgActu">
                    <img src="/images/all/35ans.jpg"/>
                </div>
                <div class="texteActualite">
                    <span>35eme anniversaire Autocolor service</span>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, perferendis consectetur enim est molestias ut, voluptate optio rerum deleniti placeat, maiores quasi nesciunt tenetur pariatur nihil ipsum delectus ipsam laboriosam!
                    </p>
                    <div class="divPlus">
                        <i class="fa-solid fa-plus"></i>
                    </div>
                </div>
                <div class="imgActu">
                    <img src="/images/all/35ans.jpg" />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>

</script>
<style scoped>
    .containerSlide {
        position: relative;
        margin: 10rem 0
    }
    .slideCarousel {
        background-color: #818F9B;
        width: 70%;
        text-align: center;
        min-height: 300px;
        padding: 2rem;
    }

    .title {
        font-size: 50px;
        font-family: Barlow;
        text-transform: uppercase;
        color: #fff;
        text-shadow: 0px 15px 6px #00000029;
    }

    .listActualites {
        position: absolute;
        top: 40%;
        left: 0;
    }

    .divActualite {
        display: flex;
        padding: 0 22rem;
        column-gap: 4rem;
    }

    .texteActualite span,
    .texteActualite p,
    .texteActualite i {
        color: #fff;
        font-family: Barlow;
    }

    .texteActualite span {
        font-size: 25px;
        text-transform: uppercase;
        font-weight: 600;
    }

    .texteActualite p {
        font-size: 20px;
        margin-bottom: 0;
    }

    .texteActualite i {
        font-size: 40px;
    }

    .divPlus {
        text-align: end;
    }

    .imgActu {
        padding: 1rem;
        background: #fff;
    }

    .imgActu img {
        width: 100%;
        height: auto;
    }
</style>