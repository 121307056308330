<template>
    <div class="productSlide">
        <span class="title">Nos nouveaux produits</span>
        <div class="divProductSlide">
            <div class="productItem">
                <div>
                    <img src="/images/all/autocolor_colle.jpg" alt="">
                </div>
                <i class="fa-solid fa-plus"></i>
            </div>
            <div class="productItem">
                <div>
                    <img src="/images/all/mastic.jpg" alt=""/>
                </div>
                <i class="fa-solid fa-plus"></i>
            </div>
            <div class="productItem">
                <div>
                    <!--<nuxt-img src="/images/all/support.png" alt=""></nuxt-img>-->
                    <img src="/images/all/support.jpg" alt="" />
                </div>
                <i class="fa-solid fa-plus"></i>
            </div>
        </div>
    </div>
</template>
<style scoped>
.productSlide {
    background-color: #0D0C0C;
    margin-top: 10rem;
    padding: 1rem 20rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.productSlide .title {
    font-size: 50px;
    font-family: Barlow;
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: #fff;
}

.divProductSlide {
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;
    align-items: center;
}

.productItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
}

.divProductRange {
    display: flex;
    justify-content: space-between;
}

.fa-plus {
    color: #fff;
}
</style>