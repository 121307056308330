<template>
    <div class="nosMarques">
        <span class="title">Nos marques</span>
        <div class="slideBrand">
            <div>
                <img src="/images/all/bg-4.png"/>
            </div>
            <div>
                <img src="/images/all/bg-5.png"/>
            </div>
            <div>
                <img src="/images/all/bg-6.png"/>
            </div>
            <div>
                <img src="/images/all/bg-9.png"/>
            </div>
            <div>
                <img src="/images/all/bg-10.png"/>
            </div>
            <div>
                <img src="/images/all/bg-12.png"/>
            </div>
            <div>
                <img src="/images/all/bg-13.png"/>
            </div>
        </div>
    </div>
</template>
<script setup>
onMounted (() => {
    $('.slideBrand').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: '<i class="fa-solid fa-chevron-left arrow_prev"></i>',
        nextArrow: '<i class="fa-solid fa-chevron-right arrow_next"></i>'
    })
})
onBeforeRouteLeave ((to, from, next) => {
    $('.slideBrand').slick('unslick')
    next()
})
</script>
<style scoped>
    .nosMarques {
        background-color: #0D0C0C0D;
        padding: 1rem 20rem;
        margin: 0 0 5rem 0;
    }

    .title {
        font-size: 50px;
        font-family: Barlow;
        text-transform: uppercase;
        font-weight: 600;
        /* color: #fff; */
        text-shadow: 0px 15px 6px #00000029;
    }

    .slideBrand {
        /* display: flex;
        column-gap: 1rem; */
        padding: 1rem 10rem;
    }

    img {
        width: 100%;
        height: auto;
    }
</style>